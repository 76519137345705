
//
// If in the customizer we add an "scroll-to-top" item, this scripts
// takes of the scroll animation
//
(function(){
  if (typeof dz_scroll_to_top.link == 'undefined') return;

  document.querySelector(dz_scroll_to_top.link).addEventListener('click', function(e) {
    e.preventDefault();
    dzScrollToTop();
  });

})();

function dzScrollToTop() {
    if (document.body.scrollTop != 0 || document.documentElement.scrollTop != 0) {
        window.scrollBy(0, -50);
        timeOut = setTimeout('dzScrollToTop()', 13);
    }
    else {
        clearTimeout(timeOut);
    }
}
